<template>
  <div>
    <container>
      <div slot="left_box_content">
        <component :is="curLeftComponent" :id.sync="id" @on-reloadList="reloadBottom"></component>
      </div>
      <div slot="right_box_content">
        <component :is="curRightComponent" ></component>
      </div>
      <div
        slot="bottom_box_content"
        style="padding:10px 10px;"
      >
        <component
          ref="bottom"
          :is="curBottomComponent"
          @on-showLeft="showLeftComponent"
        ></component>
      </div>
      <div slot="bg_map"></div>
    </container>
  </div>
</template>

<script>
import Container from '@/components/common/Container'
import { sysMixins } from '@/assets/mixins/sys'
export default {
  mixins: [sysMixins],
  components: {
    Container,
    Bottom: () => import('./components/Bottom'),
    Right: () => null,
    Left: () => import('./components/Left')
  },
  data () {
    return {
      id: 0
    }
  },
  methods: {
    showLeftComponent (id) {
      this.id = id
      this.setShowLeftBlock(true)
      this.setLeftComponent('Left')
    },
    reloadBottom () {
      this.$refs.bottom.reload()
    }
  },
  mounted () {
    this.setShowLeftBlock(false)
    this.setShowRightBlock(false)
    this.setShowMap(false)
    this.setBottomHeight('90%')
    this.setBottomComponent('Bottom')
  }
}
</script>
